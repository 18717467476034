import { useEffect, useRef } from "react";
import { logEvents } from "../../eventsManager";
import CertificationForm from "../Trademark/components/certificationForm/CertificationForm";
import CriteriaSection from "../Trademark/components/criteria/CriteriaSection";
import VeganTrademarkInfo from "../Trademark/components/vegan-trademark/VeganTrademarkInfo";
import CtaButtonsContainer from "./components/buttons/CtaButtonsContainer";
import FaqSection from "./components/faq/FaqSection";
import Hero from "./components/hero/HeroSection";
import OfferPage from "./components/offering/OfferPage/OfferPage";
import { ProductTypes } from "./components/productTypes/ProductTypes";
import LogosCarousel from "./components/VeganCompanies/Main";
import styles from "./Trademark.module.css";
import SurveyResults from "./components/surveyResults/SurveyResults";
import FlowChart from "./components/flowchart/FlowChart";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function Trademark() {
  const { country, type } = useParams();
  useEffect(() => {
    window.scrollTo({top:0,behavior:'instant'});
  }, [type]);
  const certificationRef = useRef();
  const criteriaRef = useRef();
  const faqRef = useRef();
  const refs = {
    certificationRef,
    criteriaRef,
    faqRef,
  };

  const scrollToSection = (section) => {
    if (refs[section] && refs[section].current) {
      refs[section].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        alignToTop: false,
      });
    }
  };

  const formSource =
    type === "product"
      ? "Products Page"
      : type === "business"
      ? "Businesses Page"
      : "General Page";

  return (
    <>
      <Hero type={type} />

      <div className={styles.mainContainer}>
        <div className={styles.intro}>
          <p className={styles.title}>
            {t(`trademarkPage.${type}.section1.title`)}
          </p>
          <p className={styles.description}>
            {t(`trademarkPage.${type}.section1.subtitle`)}
          </p>
        </div>
        <VeganTrademarkInfo type={type} />
        <CtaButtonsContainer
          scrollToSection={scrollToSection}
          refs={Object.keys(refs)}
        />
        <ProductTypes type={type} />
      </div>
      <SurveyResults type={type} />
      <div className={styles.mainContainer}>
        <LogosCarousel type={type} />
      </div>
      <OfferPage type={type} />
      <CriteriaSection ref={criteriaRef} type={type} />
      <FlowChart type={type} scrollToSection={scrollToSection} />
      <FaqSection ref={faqRef} type={type} scrollToSection={scrollToSection} />
      <CertificationForm ref={certificationRef} source={formSource} />
    </>
  );
}
