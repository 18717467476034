import "bootstrap/dist/css/bootstrap.min.css";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./App.module.css";
import VfNavBar from "./components/NavBar/VfNavBar";

import {
  Navigate,
  Redirect,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./Styles.css";

import { Company, Home } from "./pages";

import mixpanel, { init } from "mixpanel-browser";
import { isBrowser, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as entitiesActions from "./actions/entitiesActions";
import * as userActions from "./actions/userActions";
import VfFooter from "./components/VfFooter/VfFooter";
import config from "./config";
import PageError from "./pages/PageError/PageError";
import Search from "./pages/Search/Search";

// Import the functions you need from the SDKs you need
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getUserProperties } from "./eventsManager";
import { getOldWebsiteLink } from "./utils/oldWebsiteUtils/oldWebsiteLinksUtils";

import { ContactUsModal } from "./modals";
import {
  BLOGS,
  BRANCHES,
  BRANDS,
  EMPLOYERS,
  RECIPES,
  RESTAURANTS,
} from "./resources/config";
import { REGIONS } from "./utils/locationUtils/countriesRegions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const Home = lazy(() => import("./pages/Home/Home"));
const Restaurant = lazy(() => import("./pages/Restaurant/Restaurant"));
const Business = lazy(() => import("./pages/Business/Business"));
const Recipe = lazy(() => import("./pages/Recipe/Recipe"));
const Blog = lazy(() => import("./pages/Blog/Blog"));
const Employer = lazy(() => import("./pages/Employer/Employer"));
const VaProjects = lazy(() => import("./pages/VaProjects/VaProjects"));
const About = lazy(() => import("./pages/About/About"));
const Donate = lazy(() => import("./pages/Donate/Donate"));
const Volunteer = lazy(() => import("./pages/Volunteer/Volunteer"));
const Careers = lazy(() => import("./pages/Careers/Careers"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));
const Criteria = lazy(() => import("./pages/Criteria/Criteria"));
const AccessibilityStatement = lazy(() =>
  import("./pages/Accessibility/AccessibilityStatement")
);
const Trademark = lazy(() => import("./pages/Trademark/Trademark"));
const CertifiedPartners = lazy(() =>
  import("./pages/CertifiedPartners/CertifiedPartners")
);

export const SearchPageTypes = {
  SEARCH: "SEARCH",
  ALIAS_ENTITY: "ALIAS_ENTITY",
  ALIAS_FILTER: "ALIAS_FILTER",
  ALIAS_CHAIN: "ALIAS_CHAIN",
  ALIAS_BUSINESS: "ALIAS_BUSINESS",
};

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [hasError, setHasError] = useState(false);

  const userLocation = useSelector((state) => state.user.userLocation);
  const searchResults = useSelector((state) => state.entities.searchResults);
  const currentStateOfData = useSelector(
    (state) => state.entities.currentStateOfData
  );

  const [hasInit, setHasInit] = useState(false);

  useEffect(() => {
    initMixPanel();
    //initFirebase();
    setHasInit(true);
    checkUserUUid();
    initData();

    return () => {};
  }, []);

  useEffect(() => {
    if (userLocation && currentStateOfData != userLocation.state) {
      const reloadSearch = searchResults != null;
      let currentState = null;
      if (config.currentCountry === "US") {
        currentState = userLocation.state ? userLocation.state : null;
      }
      config.currentState = currentState;
      entitiesActions.initData(dispatch, reloadSearch, userLocation);
    }
  }, [userLocation]);

  async function initData() {
    const loginHash = localStorage.getItem("login-hash");
    const { success } = await userActions.login(loginHash);
    if (success) {
      userActions.getUserLocation(dispatch);
    } else {
      // setHasError(true);
    }
    setLoggedIn(true);

    userActions.getUserPendingReviews(dispatch);
  }

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  const initMixPanel = () => {
    mixpanel.init("75ad3cc4e8b5db062e342a5cc6f09873", { debug: false });
  };

  const checkUserUUid = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.id) {
      mixpanel.register(getUserProperties(user.id));
      userActions.setCurrentUser(dispatch, user);
    } else {
      const uuid = uuidv4();
      const newUser = { id: uuid };
      mixpanel.register(getUserProperties(newUser.id));
      localStorage.setItem("user", JSON.stringify(newUser));
      userActions.setCurrentUser(dispatch, newUser);
    }
  };

  const isFooterVisible = () => {
    return !(isMobile && location.pathname == "/search");
  };

  return hasInit ? (
    <div className="App Styles">
      <VfNavBar />
      {hasError ? (
        <PageError onTryAgain={() => initData()} />
      ) : (
        <div className={classes.pageContent}>
          <Suspense fallback={<h6>Loading..</h6>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/search"
                element={<Search type={SearchPageTypes.SEARCH} />}
              />

              <Route
                path="/chains/:chainId"
                element={<Search type={SearchPageTypes.ALIAS_CHAIN} />}
              />
              <Route
                path="/businesses/:busId"
                element={<Search type={SearchPageTypes.ALIAS_BUSINESS} />}
              />

              <Route
                path="/restaurant/:entityName/:restaurantId/"
                element={<Restaurant />}
              />
              <Route
                path="/recipe/:entityName/:recipeId/"
                element={<Recipe />}
              />
              <Route
                path="/brand/:entityName/:companyId/"
                element={<Company />}
              />
              <Route
                path="/branch/:entityName/:branchId/"
                element={<Business />}
              />

              <Route path="/blog/:blogSlug/" element={<Blog />} />
              <Route
                path="/employer/:entityName/:employerId"
                element={<Employer />}
              />
              <Route path="/vf-projects" element={<VaProjects />} />
              <Route
                path="/criteria-for-certification"
                element={<Criteria />}
              />
              <Route
                path="/accessibility"
                element={<AccessibilityStatement />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/trademark/:type" element={<Trademark />} />
              <Route
                path="/certified-partners"
                element={<CertifiedPartners />}
              />
              <Route path="/donate" element={<Donate />} />
              <Route path="/contact" element={<ContactUsModal show={true} />} />
              <Route path="/volunteer" element={<Volunteer />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/not-found" element={<PageNotFound />} />
              <Route path="*" element={<PageNotFound />} />

              {/* Search alias for entities and categories*/}
              {[RESTAURANTS, BRANCHES, RECIPES, BRANDS, BLOGS, EMPLOYERS].map(
                (entity) => (
                  <Route
                    path={`/${entity}`}
                    key={entity}
                    element={
                      <Search
                        type={SearchPageTypes.ALIAS_ENTITY}
                        entity={entity}
                      />
                    }
                  >
                    <Route
                      path=":filterName"
                      element={
                        <Search
                          type={SearchPageTypes.ALIAS_FILTER}
                          entity={entity}
                        />
                      }
                    />
                  </Route>
                )
              )}
            </Routes>
          </Suspense>
        </div>
      )}
      {isFooterVisible() && <VfFooter />}
    </div>
  ) : (
    <></>
  );
}

export default App;
