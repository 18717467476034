import { t } from "i18next";
import images from "../../../../../resources/images";
export const productCards = [
  {
    id: 1,
    image: images.trademarks.product_types.food,
    title: t("trademarkPage.section3.types.food"),
    type: "food",
  },
  {
    id: 2,
    image: images.trademarks.product_types.bevereage,
    title: t("trademarkPage.section3.types.beverages"),
    type: "beverages",
  },
  {
    id: 3,
    image: images.trademarks.product_types.vitamins,
    title: t("trademarkPage.section3.types.vitamins"),
    type: "vitamins",
  },
  {
    id: 4,
    image: images.trademarks.product_types.cosmetics,
    title: t("trademarkPage.section3.types.cosmetics"),
    type: "cosmetics",
  },
  {
    id: 5,
    image: images.trademarks.product_types.soaps,
    title: t("trademarkPage.section3.types.soaps"),
    type: "soaps",
  },
  {
    id: 6,
    image: images.trademarks.product_types.ready,
    title: t("trademarkPage.section3.types.ready"),
    type: "ready",
  },
  {
    id: 7,
    image: images.trademarks.product_types.spices,
    title: t("trademarkPage.section3.types.spices"),
    type: "spices",
  },
];
export const businessCards = [
  {
    id: 1,
    image: images.trademarks.business_types.cafe,
    title: t("trademarkPage.section3.types.food"),
    type: "cafe",
  },
  {
    id: 2,
    image: images.trademarks.business_types.bake,
    title: t("trademarkPage.section3.types.beverages"),
    type: "bakeries",
  },
  {
    id: 3,
    image: images.trademarks.business_types.ice,
    title: t("trademarkPage.section3.types.vitamins"),
    type: "ice",
  },
  {
    id: 4,
    image: images.trademarks.business_types.pizza,
    title: t("trademarkPage.section3.types.cosmetics"),
    type: "pizza",
  },
  {
    id: 5,
    image: images.trademarks.business_types.hotels,
    title: t("trademarkPage.section3.types.soaps"),
    type: "hotels",
  },
  {
    id: 6,
    image: images.trademarks.business_types.cater,
    title: t("trademarkPage.section3.types.ready"),
    type: "cater",
  },
  {
    id: 7,
    image: images.trademarks.business_types.gift,
    title: t("trademarkPage.section3.types.spices"),
    type: "gifts",
  },
  {
    id: 8,
    image: images.trademarks.business_types.clothing,
    title: t("trademarkPage.section3.types.spices"),
    type: "clothing",
  },
];
