import React, { useState, useEffect } from "react";
import FaqItem from "./FaqItem";
import styles from "./FaqSection.module.css";
import images from "../../../../resources/images";
import { t } from "i18next";
const FaqSection = React.forwardRef((props, ref) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqData, setFaqData] = useState([]);

  useEffect(() => {
    setOpenIndex(null)
    if (props.type === "product") {
      setFaqData([
        {
          id: 1,
          title: t("trademarkPage.product.section9.items.0.title"),
          firstBody: t("trademarkPage.product.section9.items.0.first_body"),
          action: t("trademarkPage.product.section9.items.0.action"),
          secondBody: t("trademarkPage.product.section9.items.0.second_body"),
          onAction: () => {
            props.scrollToSection("certificationRef");
          },
        },
        {
          id: 2,
          title: t("trademarkPage.product.section9.items.1.title"),
          body: t("trademarkPage.product.section9.items.1.body"),
        },
        {
          id: 3,
          title: t("trademarkPage.product.section9.items.2.title"),
          body: t("trademarkPage.product.section9.items.2.body"),
        },
        {
          id: 4,
          title: t("trademarkPage.product.section9.items.3.title"),
          firstBody: t("trademarkPage.product.section9.items.3.first_body"),
          action: t("trademarkPage.product.section9.items.3.action"),
          secondBody: t("trademarkPage.product.section9.items.3.second_body"),
          onAction: () => {
            props.scrollToSection("certificationRef");
          },
        },
        {
          id: 5,
          title: t("trademarkPage.product.section9.items.4.title"),
          body: t("trademarkPage.product.section9.items.4.body"),
        },
        {
          id: 6,
          title: t("trademarkPage.product.section9.items.5.title"),
          body: t("trademarkPage.product.section9.items.5.body"),
        },
        {
          id: 7,
          title: t("trademarkPage.product.section9.items.6.title"),
          body: t("trademarkPage.product.section9.items.6.body"),
        },
        {
          id: 8,
          title: t("trademarkPage.product.section9.items.7.title"),
          body: t("trademarkPage.product.section9.items.7.body"),
        },
        {
          id: 9,
          title: t("trademarkPage.product.section9.items.8.title"),
          firstBody: t("trademarkPage.product.section9.items.8.first_body"),
          action: t("trademarkPage.product.section9.items.8.action"),
          secondBody: t("trademarkPage.product.section9.items.8.second_body"),
          onAction: () => {
            props.scrollToSection("certificationRef");
          },
        },
        {
          id: 10,
          title: t("trademarkPage.product.section9.items.9.title"),
          body: t("trademarkPage.product.section9.items.9.body"),
        },
      ]);
    } else {
      setFaqData([
        {
          id: 1,
          title: t("trademarkPage.business.section9.items.0.title"),
          firstBody: t("trademarkPage.product.section9.items.0.first_body"),
          action: t("trademarkPage.product.section9.items.0.action"),
          secondBody: t("trademarkPage.product.section9.items.0.second_body"),
          onAction: () => {
            props.scrollToSection("certificationRef");
          },
        },
        {
          id: 2,
          title: t("trademarkPage.business.section9.items.1.title"),
          body: t("trademarkPage.business.section9.items.1.body"),
        },
        {
          id: 3,
          title: t("trademarkPage.business.section9.items.2.title"),
          body: t("trademarkPage.business.section9.items.2.body"),
        },
        {
          id: 4,
          title: t("trademarkPage.business.section9.items.3.title"),
          body: t("trademarkPage.business.section9.items.3.body"),
        },
        {
          id: 5,
          title: t("trademarkPage.business.section9.items.4.title"),
          body: t("trademarkPage.business.section9.items.4.body"),
        },
        {
          id: 6,
          title: t("trademarkPage.business.section9.items.5.title"),
          body: t("trademarkPage.business.section9.items.5.body"),
        },
        {
          id: 7,
          title: t("trademarkPage.business.section9.items.6.title"),
          body: t("trademarkPage.business.section9.items.6.body"),
        },
        {
          id: 8,
          title: t("trademarkPage.business.section9.items.7.title"),
          firstBody: t("trademarkPage.business.section9.items.7.first_body"),
          action: t("trademarkPage.business.section9.items.7.action"),
          secondBody: t("trademarkPage.business.section9.items.7.second_body"),
          onAction: () => {
            props.scrollToSection("certificationRef");
          },
        },
      ]);
    }
  }, [props.type]);

  return (
    <section
      ref={ref}
      className={styles.faqContainer}
      aria-label="Frequently Asked Questions"
    >
      <div className={styles.bottomLeaf}>
        <img src={images.leaf} className={styles.img} />
      </div>
      <div className={styles.topLeaf}>
        <img src={images.leaf} className={styles.img} />
      </div>
      <p className={styles.title}>FAQs</p>
      {faqData.map((item, index) => (
        <FaqItem
          key={item.id}
          item={item}
          id={item.id}
          isOpen={openIndex === index}
          onToggle={() => setOpenIndex(openIndex === index ? null : index)}
        />
      ))}
    </section>
  );
});

export default FaqSection;
