import { t } from "i18next";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config";
import { logEvents } from "../../../eventsManager";
import { NewsModal } from "../../../modals";
import { RESTAURANTS } from "../../../resources/config";
import icons, { iconsAlts } from "../../../resources/icons";
import * as links from "../../../utils/links";
import EntitiesMenu from "../EntitiesMenu.js/EntitiesMenu";
import classes from "./GetInvolvedMenu.module.css";
import GetTrademarkMenu from "../TrademarkMenu/GetTrademarkMenu";

const GetInvolvedMenu = ({
  onClose,
  contactUsHandler,
  newsletterHandler,
  getCertifiedHandler,
}) => {
  const isGB = process.env.REACT_APP_COUNTRY === "GB";
  const navigate = useNavigate();

  const logClickEvent = (buttonName) => {
    onClose();
    const params = { button_name: buttonName };
    logEvents("header_and_footer_click", params);
  };

  const isUS = config.currentCountry === "US";

  return (
    <div className={classes.mainContainer}>
      <div className={classes.itemsContainer}>
        {isMobile && <EntitiesMenu />}
        {isMobile && (
          <div
            className={classes.headerContainer}
            onClick={() => logClickEvent("get_involved")}
          >
            <p className={classes.title}>{t("get_involved")}</p>
          </div>
        )}

        {process.env.REACT_APP_COUNTRY !== "GB" && (
          <a
            className={classes.menuItem}
            target="_blank"
            href={links.URLS.donate}
            onClick={() => logClickEvent("donate")}
            rel="noreferrer"
          >
            <img
              className={classes.icon}
              src={icons.donateButton}
              alt={iconsAlts.donateButton}
            />
            <p className={classes.itemTitle}>{t("donate")}</p>
          </a>
        )}

        {process.env.REACT_APP_COUNTRY === "IL" && (
          <a
            className={classes.menuItem}
            target="_blank"
            href={links.URLS.vegan_active_homepage}
            onClick={() => logClickEvent("join_vegan_active")}
            rel="noreferrer"
          >
            <img
              className={classes.icon}
              src={icons.joinVaButton}
              alt={iconsAlts.joinVaButton}
            />
            <p className={classes.itemTitle}>{t("join_vegan_active")}</p>
          </a>
        )}

        <a
          className={classes.menuItemAboutUs}
          href={links.linkToAbout()}
          onClick={() => logClickEvent("about_us")}
        >
          <img
            className={classes.icon}
            src={icons.aboutUsButton}
            alt={iconsAlts.aboutUsButton}
          />
          <p className={classes.itemTitle}>{t("about_us")}</p>
        </a>

        <button
          type="button"
          className={classes.menuItem}
          onClick={() => {
            process.env.REACT_APP_COUNTRY === "US"
              ? links.linkToExternalPage(
                  "https://forms.monday.com/forms/7b025018c60b85488f10fd8578f67ac5?r"
                )
              : contactUsHandler(1);
            logClickEvent("volunteer");
          }}
        >
          <img
            className={classes.icon}
            src={icons.volunteerButton}
            alt={iconsAlts.volunteerButton}
          />
          <p className={classes.itemTitle}>{t("volunteer")}</p>
        </button>

        <a
          className={classes.menuItem}
          target="_blank"
          href={links.URLS.careers}
          onClick={() => logClickEvent("careers")}
          rel="noreferrer"
        >
          <img
            className={classes.icon}
            src={icons.careersButton}
            alt={iconsAlts.careersButton}
          />
          <p className={classes.itemTitle}>{t("careers")}</p>
        </a>

        <button
          type="button"
          className={classes.menuItem}
          onClick={getCertifiedHandler}
        >
          <img
            className={classes.icon}
            src={icons.getCertifiedButton}
            onClick={() => logClickEvent("get_certified")}
            alt={iconsAlts.getCertifiedButton}
          />
          <p className={classes.itemTitle}>{t("get_certified")}</p>
        </button>
        {process.env.REACT_APP_COUNTRY === "IL" && (
          <Link
            className={classes.menuItem}
            to={links.linkToCriteria()}
            onClick={() => logClickEvent("criteria-for-certification")}
          >
            <img
              className={classes.icon}
              src={icons.criteriaButton}
              alt={iconsAlts.criteriaButton}
            />
            <p className={classes.itemTitle}>
              {t("criteria_for_certification")}
            </p>
          </Link>
        )}

        {process.env.REACT_APP_COUNTRY !== "US" && (
          <button
            type="button"
            className={classes.menuItem}
            onClick={() => {
              newsletterHandler();
              logClickEvent("join_our_newsletter");
            }}
          >
            <img
              className={classes.icon}
              src={icons.newsletterButton}
              alt={iconsAlts.newsletterButton}
            />
            <p className={classes.itemTitle}>{t("join_our_newsletter")}</p>
          </button>
        )}

        <button
          type="button"
          className={classes.menuItem}
          onClick={() => {
            contactUsHandler();
            logClickEvent("contact_us");
          }}
        >
          <img
            className={classes.icon}
            src={icons.contactUsButton}
            alt={iconsAlts.contactUsButton}
          />
          <p className={classes.itemTitle}>{t("contact_us")}</p>
        </button>

        {process.env.REACT_APP_COUNTRY !== "US" && (
          <a
            className={classes.menuItem}
            href={links.URLS.download_app}
            target="_blank"
            onClick={() => logClickEvent("download_app")}
            rel="noreferrer"
          >
            <img
              className={classes.icon}
              src={icons.downloadApp}
              alt={iconsAlts.downloadApp}
            />
            <p className={classes.itemTitle}>{t("download_app")}</p>
          </a>
        )}
        {isMobile && isGB && (
          <>
            <div className={classes.headerContainer}>
              <p className={classes.title}>{t("trademark")}</p>
            </div>
            <GetTrademarkMenu />
          </>
        )}
      </div>
    </div>
  );
};

export default GetInvolvedMenu;
