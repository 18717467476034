import { RESTAURANTS, RECIPES, BRANCHES, BENEFITS, NEWS, BRANDS, BLOGS, EMPLOYERS, CERTIFIED_PARTNERS } from "../../../resources/config";
import { BusinessesItem, RecipeItem, NewsItem, CompaniesItem, BlogsItem, EmployersItem } from "../EntityGridItems";
import BenefitItem from "../EntityGridItems/BenefitItem";

export const renderItemsByType = (type, entities, isLoading, style, hoveredItem, onEntityHover) => {
  if (entities) {
    switch (type) {
      case BRANCHES:
      case RESTAURANTS:
        return getBusinessesItems(entities, type, isLoading, style, hoveredItem, onEntityHover);
      case RECIPES:
        return getRecipesItems(entities, isLoading, style);
      case BENEFITS:
        return getBenefitsItems(entities, isLoading, style);
      case NEWS:
        return getNewsItems(entities, isLoading, style);
      case BRANDS:
        return getCompaniesItems(entities, isLoading, style);
      case BLOGS:
        return getBlogsItems(entities, isLoading, style);
      case EMPLOYERS:
        return getEmployersItems(entities, isLoading, style);  
      case CERTIFIED_PARTNERS:  
      return getCertifiedPartnersItems(entities, isLoading, style);

      default:
        console.error("ERROR!! Carousel entity with invalid type: " + type);
        return;
    }
  }
  return [];
};

const getBusinessesItems = (entities, type, isLoading, style, hoveredItem, onEntityHover) => {
  const items = entities.map((entity, index) => {
    return (
      <BusinessesItem
        key={entity.branch_id ? entity.branch_id : entity.id ? entity.id : index}
        type={type}
        data={entity}
        isLoading={isLoading}
        style={style}
        isHovered={hoveredItem && entity.id === hoveredItem.id}
        onEntityHover={onEntityHover}
      />
    );
  });

  return items;
};

const getNewsItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <NewsItem key={entity.id} news={entity} isLoading={isLoading} style={style} />
  ));
};

const getRecipesItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <RecipeItem key={entity.id} data={entity} isLoading={isLoading} style={style} />
  ));
};

const getCompaniesItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <CompaniesItem key={entity.id} data={entity} isLoading={isLoading} style={style} />
  ));
};
const getCertifiedPartnersItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <CompaniesItem key={entity.id} data={entity} isLoading={isLoading} style={style} isCertifiedPartners={true}/>
  ));
};

const getBlogsItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <BlogsItem key={entity.id} data={entity} isLoading={isLoading} style={style} />
  ));
};

const getEmployersItems = (entities, isLoading, style) => {
  return entities.map((entity) => (
    <EmployersItem key={entity.id} data={entity} isLoading={isLoading} style={style} />
  ));
}


const getBenefitsItems = (entities, isLoading, style) => {
  return entities.map((entity, index) => (
    <BenefitItem
      key={"benefit" + index + entity.id}
      benefit={entity}
      isLoading={isLoading}
      style={style}
    />
  ));
};
