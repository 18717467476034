import React from "react";
import {
  Bar,
  BarChart as Chart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  Cell,
  Text,
} from "recharts";
import { t } from "i18next";

const getData = (type) => {
  return [
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.first`),
      value: 3.43,
      display: 3.43,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.second`),
      label: "Self-indicated by the brand as plant-based",
      value: 15.39,
      display: 8.39,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.third`),
      label: "Self-indicated by the brand as vegan",
      value: 31.61,
      display: 31.61,
    },
    {
      label: t(`trademarkPage.${type}.section4.survey.columns.fourth`),
      value: 55.77,
      display: 55.77,
    },
  ];
};

function CustomizedAxisTick(props) {
  const { x, y, payload } = props;

  return (
    <Text
      style={{ fontWeight: 400, fontSize: 16, color: "#596475" }}
      x={x}
      y={y}
      width={75}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
}

function BarChart({ type }) {
  const data = getData(type);
  return (
    <ResponsiveContainer
      width="100%"
      height={400}
      style={{ marginTop: "-10%" }}
    >
      <Chart data={data} margin={{ top: 0, right: 0, left: 100, bottom: 50 }}>
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={true}
          tick={<CustomizedAxisTick />}
          interval={0}
          stroke="#B9C3CE"
        />
        <Bar
          dataKey="value"
          fill="#D35177"
          radius={[15, 15, 0, 0]}
          minPointSize={50}
          maxBarSize={120}
        >
          {/* Map through each data point and assign a fill color.
              The last bar (index === data.length - 1) will have a different color. */}
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index !== data.length - 1 ? "#B9C3CE" : "#D35177"}
            />
          ))}
          {/* 
            LabelList lets you render the value or custom text inside the bar.
            position="insideTop" places it near the top inside the bar.
            Use 'formatter' to append '%' to the value.
          */}
          <LabelList
            dataKey="display"
            position="insideTop"
            fill="#fff"
            fontSize={"2rem"}
            fontWeight={"600"}
            formatter={(val) => `${val}%`}
            offset={15} // Adjust offset to move label away from bar top edge
          />
        </Bar>
      </Chart>
    </ResponsiveContainer>
  );
}

export default BarChart;
