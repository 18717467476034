import * as React from "react";
import styles from "./HeroSection.module.css";
import { t } from "i18next";
export default function HeroSection({ type }) {
  const heroBackground =
    type === "product"
      ? "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/5f0bae1872c85a7b25b7008256fc45819da3189ee44aef13ebc34f366a2dae00?apiKey=c759f4095b3644919970c2fb0a0256f9&"
      : "https://cdn.builder.io/api/v1/image/assets/c759f4095b3644919970c2fb0a0256f9/f3eb86095274a295c720ece64fb586ef3d585065486ccf779edf2ef92b915272?apiKey=c759f4095b3644919970c2fb0a0256f9&";
  return (
    <section className={styles.heroContainer}>
      <div className={styles.heroContent}>
        <img
          loading="lazy"
          src={heroBackground}
          className={styles.heroBackground}
          alt="Vegan Friendly Trademark background"
        />
        <h1 className={styles.heroTitle}>
          {t(`trademarkPage.${type}.hero.title`)}
        </h1>
        <p className={styles.heroSubtitle}>
          {t(`trademarkPage.${type}.hero.subtitle`)}
        </p>
      </div>
    </section>
  );
}
